import axios, { AxiosInstance } from "axios";
import { getRouter } from "store/global";
import { ROUTE } from "../../constants/route";

class JwtService {
  // Will be used by this service for making API calls
  axiosIns: AxiosInstance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  });

  // jwtConfig <= Will be used by this service
  jwtConfig = {
    tokenType: "Bearer",
    refreshEndpoint: "/auth/refreshtoken",
    loginEndpoint: "/auth/farmmorning",
    guestLoginEndpoint: "/auth/guest",

    // Value of this property will be used as key to store JWT token in storage
    storageTokenKeyName: "accessToken",
    storageRefreshTokenKeyName: "refreshToken",
  };

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // For Refreshing Token
  subscribers: Array<Function> = [];

  constructor() {
    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken();
        const refreshToken = this.getRefreshToken();
        const router = getRouter();
        // If token is present add it to request's Authorization Header
        if (accessToken && accessToken !== "null" && refreshToken && refreshToken !== "null") {
          // eslint-disable-next-line no-param-reassign
          config.headers = {
            ...config.headers,
            Authorization: `${this.jwtConfig.tokenType} ${accessToken}`,
          };
        } else {
          this.removeToken();

          router.replace(ROUTE.INDEX);
        }
        return config;
      },
      error => Promise.reject(error),
    );

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        const { config, response, message } = error;
        const originalRequest = config;
        const router = getRouter();
        if (
          response &&
          response.status === 401 &&
          response.data.code === "JWT-E100" &&
          config.url !== this.jwtConfig.refreshEndpoint
        ) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true;
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false;

              // Update accessToken in localStorage
              this.setToken(r.data.accessToken);
              this.setRefreshToken(r.data.refreshToken);

              this.onAccessTokenFetched(r.data.accessToken);
            });
          }
          return new Promise(resolve => {
            this.addSubscriber((accessToken: string) => {
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
              resolve(this.axiosIns(originalRequest));
            });
          });
        }
        if (response && response.status === 401 && response.data.code === "COMMON-E403") {
          this.removeToken();
          this.removeRefreshToken();

          // notify("유효하지 않은 인증정보로 접근하셨습니다!!!");
          // Redirect to login page
          throw message;
        } else if (response && response.status === 403 && response.data.code === "COMMON-E402") {
          this.removeToken();
          this.removeRefreshToken();

          router.replace("/error");
        } else if (response && response.status === 404 && response.data.code === "COMMON-E404") {
          // notify("존재하지 않는 URL입니다", "error");
          router.replace("/error");
        } else if (response && response.status === 400 && response.data.code === "COMMON-E405") {
          // notify("결과가 존재하지 않습니다", "error");
          router.replace("/error");
        } else if (response && response.status === 500 && response.data.code === "COMMON-E500") {
          // notify("알 수 없는 오류가 발생하였습니다", "error");
          router.replace("/error");
        } else if (config.url === this.jwtConfig.refreshEndpoint) {
          this.isAlreadyFetchingAccessToken = false;
          this.removeToken();
          this.removeRefreshToken();

          // notify("인증정보 갱신에 실패하였습니다!!!");

          router.push(ROUTE.INDEX);
        } else {
          router.replace("/error");
        }
        return Promise.reject(error);
      },
    );

    this.axiosIns.defaults.paramsSerializer = function (paramObj) {
      const params = new URLSearchParams();
      for (const key in paramObj) {
        params.append(key, paramObj[key]);
      }

      return params.toString();
    };
  }

  //----------------------------------
  // 인증
  //----------------------------------

  onAccessTokenFetched(accessToken: string) {
    this.subscribers = this.subscribers.filter((callback: Function) => callback(accessToken));
  }

  addSubscriber(callback: Function) {
    this.subscribers.push(callback);
  }

  getUserData() {
    return this.axiosIns.get("/user/auth");
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName);
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName);
  }

  setToken(value: string) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
  }

  setRefreshToken(value: string) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
  }

  removeToken() {
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName);
  }

  removeRefreshToken() {
    localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName);
  }

  login(...args: any[]) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args);
  }

  guestLogin(...args: any[]) {
    return this.axiosIns.post(this.jwtConfig.guestLoginEndpoint, ...args);
  }

  // 토큰 재발급
  refreshToken() {
    const refreshToken = this.getRefreshToken();
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      // username,
      refreshToken,
    });
  }

  setTempId(tid: string) {
    localStorage.setItem("tid", tid);
  }

  getTempId() {
    return localStorage.getItem("tid");
  }

  async get<T>(url: string, params?: unknown) {
    const response = await this.axiosIns.get<T>(url, { params });
    return response.data;
  }

  async post<T>(url: string, data?: unknown, options?: any) {
    const response = await this.axiosIns.post<T>(url, data, options);
    return response.data;
  }

  async put<T>(url: string, data?: unknown) {
    const response = await this.axiosIns.put<T>(url, data);
    return response.data;
  }

  async delete<T>(url: string) {
    const response = await this.axiosIns.delete<T>(url);
    return response.data;
  }

  async patch<T>(url: string, data: unknown) {
    const response = await this.axiosIns.patch<T>(url, data);
    return response.data;
  }
}

export default new JwtService();
