import { TypedJSON } from "typedjson";
import axios from "axios";

import client from "./client";
import { Design } from "vhows-design/src/object/design/Design";
import { FACILITY_TYPE } from "constants/house-type";
import { ItemModel } from "vhows-design/src/object/item/ItemModel";

export async function getDesign(uuid: string, designCode: string, itemModel: ItemModel) {
  try {
    let data = await client.costs._getB2CHCostCounselDesign(uuid, designCode, !itemModel);

    if (itemModel) {
      data.itemModel = itemModel;
    }

    const design = TypedJSON.parse(data, Design);

    design.itemModel.setAssociation();
    design.itemModel.setReferenceVariable();
    design.itemModel.setDefaultVariable();
    // 설계 객체 최신화
    design.restoreLatestObject();
    // 설계 객체 초기 설정
    design.setAssociation();
    design.setReferenceVariable();
    design.setDefaultVariable();

    return design;
  } catch (e) {
    console.error(e);
  }
}

export async function getDesignModelFacilityType(facilityType: FACILITY_TYPE) {
  try {
    return await client.costs._getB2CHCostDesignModels(facilityType);
  } catch (e) {
    console.error(e);
  }
}

export async function getProducts() {
  try {
    const AxiosIns = axios.create({
      baseURL: process.env.NEXT_PUBLIC_BASE_URL,
      headers: {
        Authorization: `VhowsAK ${process.env.NEXT_PUBLIC_API_TOKEN}`,
      },
    });
    const { data } = await AxiosIns.get<ItemModel>("/api/v1/products");

    return data;
  } catch (e) {
    console.error(e);
  }
}
