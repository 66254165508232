import { useMutation, useQuery, useQueryClient, useInfiniteQuery } from "@tanstack/react-query";
import client, { hcostResult } from "./client";
import { QueryKey } from "../constants/common";
import { getProducts } from "./design-service";
import { ItemModel } from "vhows-design/src/object/item/ItemModel";

export const useUpdateCostResultV210 = () => {
  const queryClient = useQueryClient();

  return useMutation(client.costs.updateHcostResultV210, {
    onSuccess: () => {
      queryClient.invalidateQueries(["getCostResult"]);
    },
  });
};

export const useUpdateCostResult = () => {
  const queryClient = useQueryClient();

  return useMutation(client.costs.updateHcostResult, {
    onSuccess: () => {
      queryClient.invalidateQueries(["getCostResult"]);
    },
  });
};

export const useCreateCostResult = () => {
  return useMutation(client.costs.createHcostResult);
};

export const useGetCostResult = (resultId: string | null) => {
  const { data, isSuccess, status, isLoading } = useQuery<any, Error>(
    ["getCostResult", resultId],
    () => client.costs.getHcostResult(resultId),
    {
      staleTime: 50000,
      enabled: !!resultId,
    },
  );

  return { data, isSuccess, status, isLoading };
};

export const useGetCostModels = () => {
  const { data, isSuccess } = useQuery(["getCostModel"], () => client.costs.getHcostModels());

  return { data, isSuccess };
};

export const useCreateCostEstimate = () => {
  return useMutation(client.costs.createHcostEstimate);
};

export const useCreateHcostCounsel = () => {
  return useMutation(client.costs.createHcostCounsel);
};

export const useCreateHcostCounselGlass = () => {
  return useMutation(client.costs.createHcostCounselGlass);
};

export const useUpdateHcostEstimate = () => {
  return useMutation(client.costs.updateHcostEstimate);
};

export const useCreateCounsel = () => {
  return useMutation(client.costs._createHCostCounsel);
};

export const useUpdateCounsel = () => {
  const queryClient = useQueryClient();

  return useMutation(client.costs._updateHcostCounsel, {
    onSuccess: () => {
      queryClient.invalidateQueries(["getCounsel"]);
    },
  });
};

export const useGetCounsel = (uuid: string) => {
  const { data, isSuccess, status, isLoading, error } = useQuery<any, Error>(
    ["getCounsel", uuid],
    () => client.costs._getHcostCounsel(uuid),
    {
      staleTime: 50000,
      enabled: !!uuid,
    },
  );

  if (error) {
    throw error;
  }

  return { data, isSuccess, status, isLoading };
};

export const useCreateCounselNotification = () => {
  return useMutation(client.costs._createHcostCounselNotification);
};

export const useGetB2CHCostCounselList = (args: B2CCounselListParams, isToken?: boolean, key?: string) => {
  return useInfiniteQuery<B2CCounsel[], Error>(
    [QueryKey.GET_B2C_COUNSEL_LIST, key],
    async ({ pageParam = 1 }) =>
      client.costs._getB2CHCostCounselList({
        size: args.size,
        page: pageParam,
        counselTaken: true,
        progressStatusGroups: args.progressStatusGroups,
      }),
    {
      getNextPageParam: data => {
        return data.length === args.size ? args.page + 1 : undefined;
      },
      cacheTime: 0,
      staleTime: 0,
      enabled: isToken ?? true,
    },
  );
};

export const useCreateB2CHCostCounsel = () => {
  return useMutation(client.costs._createB2CHCostCounsel);
};

export const useUpdateB2CHCostCounsel = () => {
  const queryClient = useQueryClient();

  return useMutation(client.costs._updateB2CHCostCounsel, {
    onSuccess: async data => {
      // await queryClient.invalidateQueries({ queryKey: [QueryKey.GET_B2C_COUNSEL] });
    },
  });
};

export const useGetB2CHCostCounsel = (uuid: string) => {
  return useQuery<B2CCounsel, Error>([QueryKey.GET_B2C_COUNSEL, uuid], () => client.costs._getB2CHCostCounsel(uuid), {
    cacheTime: 0,
    staleTime: 0,
    enabled: !!uuid,
  });
};

export const useCreateB2CHCostCounselNotification = () => {
  return useMutation(client.costs._createB2CHCostCounselNotification);
};

export const useCancelB2CHCostCounsel = () => {
  return useMutation(client.costs._cancelB2CHCostCounsel);
};

export const useGetB2CHCostDesignModel = (facilityType: FACILITY_TYPE | null) => {
  return useQuery<DesignModel, Error>(
    [QueryKey.GET_B2C_DESIGN_MODEL, facilityType],
    () => client.costs._getB2CHCostDesignModels(facilityType),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!facilityType,
    },
  );
};

export const useGetProducts = () => {
  return useQuery<ItemModel, Error>([QueryKey.GET_PRODUCTS], () => getProducts(), {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};
