import HttpClient from "./http-client";
import { QA_FLAG_STAGING, DEV_ENV, checkVersion } from "@vhows/util";
import { FACILITY_TYPE } from "../../constants/house-type";
import { Design } from "vhows-design/src/object/design/Design";

export interface resultFactor {
  id: number;
  costAvg?: number;
  factorSelected: boolean;
  factorCat1?: string;
  factorCat2?: string;
  descNote?: string;
  factorName?: string;
  factorValue?: string;
  parentDescNote?: string;
}

export interface hcostResult {
  id?: string;
  customerName?: string;
  customerPhone?: string;
  customerEmail?: string;
  modelKey?: string;
  modelTitle?: string;
  basicAreaPyeong?: number;
  conRegion?: string;
  conDate?: string | Date;
  conSupport?: string;
  conSupportType?: string;
  conUsage?: string;
  conFarming?: boolean;
  conFarmmorning?: boolean;
  firstEstimating?: boolean;
  counselTaken?: boolean;
  counselMemo?: string;
  privacyAgree?: boolean;
  marketingAgree?: boolean;
  marketingAgreeDate?: string | Date;
  totalCostAvg?: number;
  totalCostDefault?: number;
  inflowType?: string;
  resultFactors?: resultFactor[];
  conWidth?: string;
  conLength?: string;
}

export interface hcostModel {
  modelKey: string;
  modelTitle: string;
  orderIndex: number;
  descNote: string;
}

export interface inboundModel {
  name: string;
  phoneNumber: string;
  inboundType: string;
  construction: {
    basicAreaPyeong: number;
    conDate: string | Date;
    conRegion: string;
    conSupportType: string;
    conUsage: string;
    counselMemo: string;
    counselTaken: boolean;
    conFarming?: boolean;
    conLandOwnershipType?: string;
    conFarmmorning?: boolean;
    firstEstimating: boolean;
    marketingAgreeDate: string | Date;
    modelKey: string;
    privacyAgree: boolean;
    totalCostAvg: number;
    resultFactors: resultFactor[] | string;
  };
}

export interface hcostEstimate {
  id: string;
  modelKey: string;
  basicAreaPyeong: number;
}

export const InboundData = {
  conFarming: {
    label: "",
    text: "",
    items: [
      {
        text: "경력 없음",
        value: "경력 없음",
      },
      {
        text: "1년 미만",
        value: "1년 미만",
      },
      {
        text: "1~5년 미만",
        value: "1~5년 미만",
      },
      {
        text: "5~10년 미만",
        value: "5~10년 미만",
      },
      {
        text: "10~20년 미만",
        value: "10~20년 미만",
      },
      {
        text: "20년 이상",
        value: "20년 이상",
      },
    ],
  },
  conUsage: {
    label: "",
    text: "",
    items: [
      {
        text: "농막 하우스",
        value: "농막 하우스",
      },
      {
        text: "텃밭/농사 하우스",
        value: "텃밭/농사 하우스",
      },
      {
        text: "반반 하우스",
        value: "반반 하우스",
      },
      {
        text: "비가림 하우스",
        value: "비가림 하우스",
      },
      {
        text: "기타",
        value: "기타",
      },
    ],
  },

  conLandOwnershipType: {
    items: [
      {
        text: "토지 소유중",
        value: "소유중",
      },
      {
        text: "토지 임차중",
        value: "임차중",
      },
      {
        text: "토지 계약 준비중",
        value: "계약 준비중",
      },
      // {
      //   text: "토지 계약 미정",
      //   value: "미정",
      // },
    ],
  },

  thirdPartyPrivacy: {
    items: [
      {
        text: "개인정보 제3자 제공 동의 (필수)",
        url: "https://greenlabs.notion.site/3-fe0aa3c8f26745b0a4b3f38d783910d7",
        value: 1,
      },
      // {
      //   text: "그린랩스 개인정보 제3자 제공 동의(필수)",
      //   url: "https://farmmorning-howscost-terms.oopy.io/48efdb02-1b65-41ab-9352-47e308916ad2",
      //   value: 2,
      // },
    ],
  },
};

function makeApiPath() {
  return checkVersion("3.0.0", QA_FLAG_STAGING)
    ? "/api/v3.0"
    : checkVersion("2.1.0", QA_FLAG_STAGING)
    ? "/api/v2.1"
    : checkVersion("2.0.0", QA_FLAG_STAGING)
    ? "/api/v2"
    : "/api";
}

function makeCounselApiPath() {
  return "/api/v4";
}

class Client {
  costs = {
    //----------------------------------
    // 간편견적
    //----------------------------------

    // 간편견적 결과 조희
    getHcostResult: (resultId: string | null) =>
      HttpClient.get<HcostResult>(`${makeApiPath()}/hcosts/results/${resultId}`),

    // 간편견적 결과 등록
    createHcostResult: (args: HcostResult) => HttpClient.post<HcostResult>(`${makeApiPath()}/hcosts/results`, args),

    // 간편견적 결과 수정
    updateHcostResult: (args: HcostResult) =>
      HttpClient.put<HcostResult>(`${makeApiPath()}/hcosts/results/${args.id}`, args),

    // 간편견적 결과 수정v2.1.0
    updateHcostResultV210: (args: HcostResultV210) =>
      HttpClient.put<HcostResultV210>(`${makeApiPath()}/hcosts/results/${args.id}`, args),

    // 간편견적 모델 목록 조회
    getHcostModels: () => HttpClient.get<HcostModel[]>(`${makeApiPath()}/hcosts/models`),

    // 간편견적 비용 계산
    createHcostEstimate: (args: HcostEstimate) =>
      HttpClient.post<HcostResult>(`${makeApiPath()}/hcosts/results/${args.id}/estimate`, args),

    // 간편견적 비용 재계산
    updateHcostEstimate: (args: { id: string; resultFactors: ResultFactor[] | undefined }) =>
      HttpClient.put<HcostResult>(`${makeApiPath()}/hcosts/results/${args.id}/estimate`, args),

    // 간편견적 상담 신청
    createHcostCounsel: (resultId: string) =>
      HttpClient.post<HcostResult>(`${makeApiPath()}/hcosts/results/${resultId}/counsel`),

    // 유리온실 전문 상담 신청
    createHcostCounselGlass: (args: HcostResult) => HttpClient.post(`${makeApiPath()}/hcosts/counsel/glass`, args),

    // 간편 상담 등록
    _createHCostCounsel: (args: HcostCounsel) =>
      HttpClient.post<HcostCounsel>(`${makeCounselApiPath()}/hcosts/counsel`, args),

    // 간편 상담 수정
    _updateHcostCounsel: (args: HcostCounsel) =>
      HttpClient.put<HcostCounsel>(`${makeCounselApiPath()}/hcosts/counsel/${args.uuid}`, args),

    // 간편 상담 조회
    _getHcostCounsel: (uuid: string) => HttpClient.get<HcostCounsel>(`${makeCounselApiPath()}/hcosts/counsel/${uuid}`),

    // 간편 상담 신청
    _createHcostCounselNotification: (uuid: string) =>
      HttpClient.post<{ uuid: string }>(`${makeCounselApiPath()}/hcosts/counsel/${uuid}/notification`),

    // 시공상담 리스트 조회
    _getB2CHCostCounselList: (args: B2CCounselListParams) =>
      HttpClient.get<B2CCounsel[]>(`/b2c/v5/hcosts/counsel`, args),

    // 시공 상담 등록
    _createB2CHCostCounsel: (args: Pick<B2CCounsel, "stepCode" | "serviceVersion">) =>
      HttpClient.post<{ uuid: string }>(`/b2c/v5/hcosts/counsel`, args),

    // 시공 상담 상담 신청
    _createB2CHCostCounselNotification: (uuid: string) =>
      HttpClient.post<{ uuid: string }>(`/b2c/v5/hcosts/counsel/${uuid}/notification`),

    // 시공 상담 조회
    _getB2CHCostCounsel: (uuid: string) => HttpClient.get<B2CCounsel>(`/b2c/v5/hcosts/counsel/${uuid}`),

    // 시공 상담 수정
    _updateB2CHCostCounsel: (args: Partial<B2CCounsel>) =>
      HttpClient.patch<B2CCounsel>(`/b2c/v5/hcosts/counsel/${args.uuid}`, args),

    // 시공상담 진행현황 조회
    _getB2CHCostProgress: () => HttpClient.get<CounselProgress>(`/b2c/v5/hcosts/counsel/progress`),

    // 시공상담 취소
    _cancelB2CHCostCounsel: (args: Partial<B2CCounsel>) =>
      HttpClient.put(`/b2c/v5/hcosts/counsel/${args.uuid}/cancel`, args),

    // 시공 상담 고객 설계 데이터 조회
    _getB2CHCostCounselDesign: (uuid: string, designCode: string, itemModelFlag: boolean) =>
      HttpClient.get<Design>(`/b2c/v5/hcosts/counsel/${uuid}/designs/${designCode}`, { itemModelFlag }),

    // 시공 상담 설계 모델 B2C
    _getB2CHCostDesignModels: (facilityType: FACILITY_TYPE) =>
      HttpClient.get<DesignModel>(`/b2c/v1/hcosts/designs/models/facility-type/${facilityType}`),
  };
}

export default new Client();
